/**
 * Class element
 * Header component
 * Display the header
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { BsX } from "react-icons/bs"
import Headerfixeddesktop from "../components/headerfixeddesktop"
import Headerfixedtablet from "../components/headerfixedtablet"
import Headertop from "../components/headertop"
import Logo from "../components/logo"
import Navmaintablet from "../components/navmaintablet"
import "../styles/components/header.scss"


/* Declaration class */
class Header extends Component {

  constructor() {
    super()
    this.state = {
      width: 0,
      onClicked: false
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }



  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  manageBurger(boolStateBurger) {
    var navcontainer = [].slice.call((document.getElementsByClassName("nav-container-tablet")))
    var headermegamenutablet = [].slice.call((document.getElementsByClassName("header-mega-menu-tablet")))
    var wrapmegamenutablet = [].slice.call((document.getElementsByClassName("wrap-mega-menu-tablet")))
    var megamenuitem = [].slice.call((document.getElementsByClassName("mega-menu-item-tablet")))
    var menuitem = [].slice.call((document.getElementsByClassName("menu-item-tablet")))
    var svgicon = [].slice.call((document.getElementsByClassName("svg-icon")))
    var submenu = [].slice.call((document.getElementsByClassName("submenu-tablet")))

    if(boolStateBurger === true && this.state.width <= 1280){
      document.body.style.overflow = 'hidden'
      headermegamenutablet[0].classList.add("show")
      wrapmegamenutablet[0].classList.add("show")
    }else{
      document.body.style.overflow = 'auto'
      navcontainer[0].classList.remove("dropped")
      headermegamenutablet[0].classList.remove("show")
      wrapmegamenutablet[0].classList.remove("show")
      megamenuitem.forEach((item) => {
        item.classList.remove("current")
        item.classList.add("inactive")
      })
      menuitem.forEach((item) => {
        item.classList.remove("current")
      })
      svgicon.forEach((item) => {
        item.classList.remove("current")
      })
      submenu.forEach((item) => {
        item.classList.remove("current")
      })
    }

    this.setState({
      width: window.innerWidth,
    })
  }

  handlerButtonOnClick(){
    var wrapmegamenutablet = [].slice.call((document.getElementsByClassName("wrap-mega-menu-tablet")))
    wrapmegamenutablet[0].classList.remove("show")
    var headermegamenutablet = [].slice.call((document.getElementsByClassName("header-mega-menu-tablet")))
    headermegamenutablet[0].classList.remove("show")
    var megamenuitem = [].slice.call((document.getElementsByClassName("mega-menu-item-tablet")))
    megamenuitem.forEach((item) => {
      item.classList.remove("current")
      item.classList.add("inactive")
    })
    document.body.style.overflow = 'auto';
    this.setState({
       onClicked: true
    })
  }

  handleEvent = () => {
    document.body.style.overflow = 'auto';
  }

  render () {
    const isTablet = this.state.width <= 1280

    return (
      <header className="main-header">
        {/* Top Header */}
        {!isTablet && <Headertop />}

        {/* Sticky Header */}
        {isTablet ? <Headerfixedtablet /> : <Headerfixeddesktop />}

        {/* Wrap sub menu nav */}
        {isTablet ? (
          <div className="wrap-mega-menu-tablet" aria-hidden="true">
            <div className="header-mega-menu-tablet">
              <div className="main-logo">
                <Link to="/" onClick={this.handlerButtonOnClick}><Logo /></Link>
              </div>
              <div className="close-burger">
                <button className="button-burger" onClick={() => this.manageBurger(false)} aria-label="Close the menu">
                  <span>
                    <IconContext.Provider value={{ className: "svg-burger" }}>
                    <i aria-hidden="true"><BsX /></i>
                    </IconContext.Provider>
                  </span>
                </button>
              </div>
            </div>
            <Navmaintablet key="tablet-menu"/>
          </div>
        ) : (
          <div className="wrap-mega-submenu-desktop" aria-hidden="true"></div>
        )
        }
      </header>
    )
  }
}

/* Export class */
export default Header
