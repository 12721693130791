/**
 * Class element
 * Mega Menu Desktop component
 * Display the mega menu desktop in main nav
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { BsChevronDown } from "react-icons/bs"
import "../styles/components/megamenudesktop.scss"

/* Declaration class */
class Megamenudesktop extends Component {

  render () {
    const tabItem = this.props.tabList
    const nameItem = this.props.nameList
    const arrItem = this.props.arrList
    const classCurrent = (this.props.current) ? "current":""
    const { onPress } = this.props

    return (
      <li key={`item-${tabItem}`} className={`mega-menu-item ${classCurrent}`} onClick={this.props.onClick} onKeyDown={this.props.onKeyDown} role="presentation">
        <span className={`menu-item ${classCurrent}`}>
          <span id="desktopDropdownMenuLink" className="span-item" data-toggle="dropdown" aria-expanded="false">{nameItem}</span>
          <IconContext.Provider value={{ className: "icon-arrow"}}>
            <span className={`svg-icon ${classCurrent}`}><BsChevronDown /></span>
          </IconContext.Provider>
          <span className="active-bar"></span>
        </span>
        <ul className={`mega-submenu ${classCurrent}`} aria-labelledby="desktopDropdownMenuLink">
          <li className="submenu-infos"></li>
          {arrItem.map((item, index) =>
          <li key={`list-${tabItem}-${index}`} className="submenu-category">
            <span className={item.itemColor}>{item.itemCat}</span>
            <ul className="mega-submenu-col">
            {item.arrItemCat.map((itemlist, index) =>
              <li key={`sublist-${tabItem}-${index}`}><Link to={itemlist.to} onClick={onPress}>{itemlist.cta}</Link></li>
            )}
            </ul>
          </li>
          )}
        </ul>
      </li>
    )
  }
}

/* Export function */
export default Megamenudesktop
