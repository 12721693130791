/**
 * Const with params element
 * CTA Button component
 * Display a link as a button
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import "../styles/components/ctabutton.scss"

/* Declaration class */
class Ctabutton extends Component {

  constructor(props) {
    super(props)
    this.state = {
      onClicked: false
    }
    this.handlerButtonOnClick = this.handlerButtonOnClick.bind(this)
  }

  handlerButtonOnClick(){
    document.body.style.overflow = 'auto';
    this.setState({
       onClicked: true
    })
  }

  render () {
    const defaultStyle = "cta-button"
    const idCta = this.props.idCta
    const classCta = this.props.classCta
    const ctaLabel = this.props.ctaLabel
    const ctaLink = this.props.ctaLink
    const colorStyle = this.props.colorStyle
    const extLink = this.props.extLink

    if(extLink) {
      return <a id={idCta} onClick={this.handlerButtonOnClick} className={`${defaultStyle} ${colorStyle} ${classCta}`} href={ctaLink} target="_blank" rel="noreferrer" aria-label={ctaLabel}><span className="wrap-spanbutton"><span className="spanbutton-label">{ctaLabel}</span></span></a>
    }else {
      return <Link id={idCta} onClick={this.handlerButtonOnClick} className={`${defaultStyle} ${colorStyle} ${classCta}`} to={`${ctaLink}/`}><span className="wrap-spanbutton"><span className="spanbutton-label" aria-label={ctaLabel}>{ctaLabel}</span></span></Link>
    }

  }
}

/* Export function */
export default Ctabutton
