/**
 * Class element
 * Mega Menu Tablet component
 * Display the mega menu in main nav in tablet
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { BsChevronRight,  BsArrowLeftShort } from "react-icons/bs"
import Bottommenutablet from "../components/bottommenutablet"
import "../styles/components/megamenutablet.scss"

/* Declaration class */
class Megamenutablet extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hideToggleMenu: false,
      onClicked: false
    }

    this.handlerButtonOnClick = this.handlerButtonOnClick.bind(this)
  }

  toggleMegaMenu(e) {
    this.setState({
      hideToggleMenu: !this.state.hideToggleMenu,
      onClicked: false
    })

    var navcontainer = [].slice.call((document.getElementsByClassName("nav-container-tablet")))
    navcontainer[0].classList.remove("dropped")
  }

  handlerButtonOnClick(){
    var wrapmegamenutablet = [].slice.call((document.getElementsByClassName("wrap-mega-menu-tablet")))
    wrapmegamenutablet[0].classList.remove("show")
    var headermegamenutablet = [].slice.call((document.getElementsByClassName("header-mega-menu-tablet")))
    headermegamenutablet[0].classList.remove("show")
    var megamenuitem = [].slice.call((document.getElementsByClassName("mega-menu-item-tablet")))
    megamenuitem.forEach((item) => {
      item.classList.remove("current")
      item.classList.add("inactive")
    })
    document.body.style.overflow = 'auto';
    this.setState({
      hideToggleMenu: !this.state.hideToggleMenu,
      onClicked: true
    })
  }


  render () {
    const tabItem = this.props.tabList
    const nameItem = this.props.nameList
    const arrItem = this.props.arrList
    const classActive = (this.state.hideToggleMenu) ? "" : "inactive"

    return (
      <li key={`item-${tabItem}`} id={`list-${tabItem}`} className={`mega-menu-item-tablet ${classActive}`} onClick={this.props.onClick} onKeyDown={this.props.onKeyDown} role="presentation">
        <span id={`menu-item-tablet-${tabItem}`} className={`menu-item-tablet ${classActive}`}>
          <span id={`span-item-tablet-${tabItem}`} className="span-item-tablet">{nameItem}</span>
          <IconContext.Provider value={{ className: "icon-arrow"}}>
            <span id={`svg-icon-${tabItem}`} className={`svg-icon ${classActive}`}><BsChevronRight /></span>
          </IconContext.Provider>
        </span>
        <div id={`submenu-tablet-${tabItem}`} className={`submenu-tablet ${classActive}`} >
          <button className="button-toggle" onClick={(e) => this.toggleMegaMenu(e)} onKeyDown={(e) => this.toggleMegaMenu(e)} >
            <IconContext.Provider value={{ className: "icon-toggle" }}>
              <BsArrowLeftShort />
            </IconContext.Provider>
            <span className="button-text">{nameItem}</span>
          </button>
          <ul className="mega-submenu-tablet">
            {arrItem.map((item, index) =>
            <li key={`list-${tabItem}-${index}`} className="submenu-category">
              <span className={item.itemColor}>{item.itemCat}</span>
              <ul className="mega-submenu-col">
              {item.arrItemCat.map((itemlist, index) =>
                <li key={`sublist-${tabItem}-${index}`}><Link to={itemlist.to} onClick={this.handlerButtonOnClick}>{itemlist.cta}</Link></li>
              )}
              </ul>
            </li>
            )}
          </ul>
          <Bottommenutablet />
        </div>
      </li>
    )
  }
}

/* Export function */
export default Megamenutablet

