/**
 * Class element
 * Main Nav Tablet component
 * Display the main nav in tablet
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import Bottommenutablet from "../components/bottommenutablet"
import Megamenutablet from "../components/megamenutablet"
import MenuNavJSon from "../../content/menu-nav.json"
import "../styles/components/navmaintablet.scss"

/* Declaration class */
class Navmaintablet extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeSubMenuTablet:"",
      activeSolutions: false,
      activeLearn:false,
      activeResources:false,
      onClicked: false
    }
    this.handlerButtonOnClick = this.handlerButtonOnClick.bind(this)
  }

  handleClick = (e, tabId) => {

    if(e.target.id === "menu-item-tablet-solutions" || e.target.id === "menu-item-tablet-learn" || e.target.id === "menu-item-tablet-resources" || e.target.id === "menu-item-tablet-company" ||
      e.target.id === "span-item-tablet-solutions" || e.target.id === "span-item-tablet-learn" || e.target.id === "span-item-tablet-resources" || e.target.id === "span-item-tablet-company" ||
      e.target.id === "submenu-tablet-solutions" || e.target.id === "submenu-tablet-learn" || e.target.id === "submenu-tablet-resources" || e.target.id === "submenu-tablet-company" ||
      e.target.className === "menu-item-tablet" || e.target.className.animVal === "icon-arrow") {

      var navcontainer = [].slice.call((document.getElementsByClassName("nav-container-tablet")))
      navcontainer[0].classList.add("dropped")
      var megamenuitem = [].slice.call((document.getElementsByClassName("mega-menu-item-tablet")))
      megamenuitem.forEach((item) => {
        item.classList.remove("current")
        item.classList.add("inactive")
      })
      var menuitem = [].slice.call((document.getElementsByClassName("menu-item-tablet")))
      menuitem.forEach((item) => {
        item.classList.remove("current")
      })
      var svgicon = [].slice.call((document.getElementsByClassName("svg-icon")))
      svgicon.forEach((item) => {
        item.classList.remove("current")
      })
      var submenu = [].slice.call((document.getElementsByClassName("submenu-tablet")))
      submenu.forEach((item) => {
        item.classList.remove("current")
      })

      if(tabId === 1) {
        this.setState({
          activeSubMenuTablet:"dropped",
          activeSolutions: true,
          activeLearn:false,
          activeResources:false,
        })

        document.getElementById("list-solutions").classList.remove("inactive")
        document.getElementById("list-solutions").classList.add("current")
        document.getElementById("menu-item-tablet-solutions").classList.add("current")
        document.getElementById("svg-icon-solutions").classList.add("current")
        document.getElementById("submenu-tablet-solutions").classList.remove("inactive")
        document.getElementById("submenu-tablet-solutions").classList.add("current")

      } else if(tabId === 2) {
        this.setState({
          activeSubMenuTablet:"dropped",
          activeSolutions: false,
          activeLearn:true,
          activeResources:false,
        })
        document.getElementById("list-learn").classList.remove("inactive")
        document.getElementById("list-learn").classList.add("current")
        document.getElementById("menu-item-tablet-learn").classList.add("current")
        document.getElementById("svg-icon-learn").classList.add("current")
        document.getElementById("submenu-tablet-learn").classList.remove("inactive")
        document.getElementById("submenu-tablet-learn").classList.add("current")
      } else if(tabId === 3) {
        this.setState({
          activeSubMenuTablet:"dropped",
          activeSolutions: false,
          activeLearn:false,
          activeResources:true,
        })
        document.getElementById("list-resources").classList.remove("inactive")
        document.getElementById("list-resources").classList.add("current")
        document.getElementById("menu-item-tablet-resources").classList.add("current")
        document.getElementById("svg-icon-resources").classList.add("current")
        document.getElementById("submenu-tablet-resources").classList.remove("inactive")
        document.getElementById("submenu-tablet-resources").classList.add("current")
      } else {
        this.setState({
          activeSubMenuTablet:"dropped",
          activeSolutions: false,
          activeLearn:false,
          activeResources:true,
        })
        document.getElementById("list-company").classList.remove("inactive")
        document.getElementById("list-company").classList.add("current")
        document.getElementById("menu-item-tablet-company").classList.add("current")
        document.getElementById("svg-icon-company").classList.add("current")
        document.getElementById("submenu-tablet-company").classList.remove("inactive")
        document.getElementById("submenu-tablet-company").classList.add("current")
      }
    } else{
      this.setState({
        activeSubMenuTablet:"",
        activeSolutions: false,
        activeLearn:false,
        activeResources:false,
      })
    }
  }

  handlerButtonOnClick(){
    var wrapmegamenutablet = [].slice.call((document.getElementsByClassName("wrap-mega-menu-tablet")))
    wrapmegamenutablet[0].classList.remove("show")
    var headermegamenutablet = [].slice.call((document.getElementsByClassName("header-mega-menu-tablet")))
    headermegamenutablet[0].classList.remove("show")
    var megamenuitem = [].slice.call((document.getElementsByClassName("mega-menu-item-tablet")))
    megamenuitem.forEach((item) => {
      item.classList.remove("current")
      item.classList.add("inactive")
    })
    document.body.style.overflow = 'auto';
    this.setState({
       onClicked: true
    })
  }

  render () {
    //Elements of the Mega Menu - Solutions
    let arrSubListProducts = MenuNavJSon.subListProducts
    let arrSubListSpecialties= MenuNavJSon.subListSpecialties
    let arrSubListBenefits = MenuNavJSon.subListBenefits
    let arrSubListServices = MenuNavJSon.subListServices
    let arrMegaMenuSolutions = [
      {itemColor:  MenuNavJSon.megaMenuSolutions[0].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[0].itemCat, arrItemCat: arrSubListProducts},
      {itemColor:  MenuNavJSon.megaMenuSolutions[1].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[1].itemCat, arrItemCat: arrSubListSpecialties},
      {itemColor:  MenuNavJSon.megaMenuSolutions[2].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[2].itemCat, arrItemCat: arrSubListBenefits},
      {itemColor:  MenuNavJSon.megaMenuSolutions[3].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[3].itemCat, arrItemCat: arrSubListServices},
    ]

    //Elements of the Mega Menu - Learn
    let arrSubListFaqs = MenuNavJSon.subListFaqs
    let arrSubListTrainingCenter= MenuNavJSon.subListTrainingCenter
    let arrSubListReleaseCenter= MenuNavJSon.subListReleaseCenter
    let arrMegaMenuLearn = [
      {itemColor:  MenuNavJSon.megaMenuLearn[0].itemColor, itemCat: MenuNavJSon.megaMenuLearn[0].itemCat, arrItemCat: arrSubListFaqs},
      {itemColor:  MenuNavJSon.megaMenuLearn[1].itemColor, itemCat: MenuNavJSon.megaMenuLearn[1].itemCat, arrItemCat: arrSubListTrainingCenter},
      {itemColor:  MenuNavJSon.megaMenuLearn[2].itemColor, itemCat: MenuNavJSon.megaMenuLearn[2].itemCat, arrItemCat: arrSubListReleaseCenter},
    ]

    //Elements of the Mega Menu - Resources
    let arrSubListBlogNews = MenuNavJSon.subListBlogNews
    let arrSubListResource1= MenuNavJSon.subListResource1
    let arrSubListResource2= MenuNavJSon.subListResource2
    let arrMegaMenuResources = [
      {itemColor:  MenuNavJSon.megaMenuResources[0].itemColor, itemCat: MenuNavJSon.megaMenuResources[0].itemCat, arrItemCat: arrSubListBlogNews},
      {itemColor:  MenuNavJSon.megaMenuResources[1].itemColor, itemCat: MenuNavJSon.megaMenuResources[1].itemCat, arrItemCat: arrSubListResource1},
      {itemColor:  MenuNavJSon.megaMenuResources[2].itemColor, itemCat: MenuNavJSon.megaMenuResources[2].itemCat, arrItemCat: arrSubListResource2},
    ]

    //Elements of the Mega Menu - Company
    let arrSubListCompany = MenuNavJSon.subListCompany
    let arrMegaMenuCompany = [
      {itemColor:  MenuNavJSon.megaMenuCompany[0].itemColor, itemCat: MenuNavJSon.megaMenuCompany[0].itemCat, arrItemCat: arrSubListCompany},
    ]

    return (
      <div className={`nav-container-tablet ${this.state.activeSubMenuTablet} `}>
        <nav key="mainnavtablet" className="main-nav-tablet" role="navigation">
          <ul key="list-mainnav" className="primary-menu-tablet">
            <Megamenutablet
              key="solutions"
              tabList="solutions"
              nameList="Solutions"
              arrList={arrMegaMenuSolutions}
              onClick={(e) => this.handleClick(e, 1)}
              onKeyDown={(e) => this.handleClick(e, 1)}/>
            <li className="simple-menu-item-tablet"><Link onClick={this.handlerButtonOnClick} to={MenuNavJSon.simpleMenuItem[0].to}>{MenuNavJSon.simpleMenuItem[0].cta}</Link></li>
            <li className="simple-menu-item-tablet"><Link onClick={this.handlerButtonOnClick} to={MenuNavJSon.simpleMenuItem[1].to}>{MenuNavJSon.simpleMenuItem[1].cta}</Link></li>
            <Megamenutablet
              key="learn"
              tabList="learn"
              nameList="Learn"
              arrList={arrMegaMenuLearn}
              onClick={(e) => this.handleClick(e, 2)}
              onKeyDown={(e) => this.handleClick(e, 2)}/>
            <Megamenutablet
              key="resources"
              tabList="resources"
              nameList="Resources"
              arrList={arrMegaMenuResources}
              onClick={(e) => this.handleClick(e, 3)}
            onKeyDown={(e) => this.handleClick(e, 3)}/>
            <Megamenutablet
              key="company"
              tabList="company"
              nameList="Company"
              arrList={arrMegaMenuCompany}
              onClick={(e) => this.handleClick(e, 4)}
              onKeyDown={(e) => this.handleClick(e, 4)}/>
          </ul>
        </nav>
        <Bottommenutablet />
      </div>
    )
  }
}

/* Export function */
export default Navmaintablet
