/**
 * Const with params element
 * SEO component
 * Display SEO information from YOAST with HELMET
 */
 /* Import section */
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

/* Declaration function */
const SEO = ({ title, description, datastructured, datastructuredfaq, articleId, articlePicture, classHtml, classbody, slickslider  }) => {
  const { site, dataFAQ, dataFAQtelehealth, dataArticle, defaultImage, defaultLogo, defaultThumbnailDemo, defaultThumbnailIntro } = useStaticQuery(query)
  const { pathname } = useLocation()

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    siteLanguage,
  } = site.siteMetadata

  const ogImage = defaultImage.childImageSharp.gatsbyImageData.images.fallback.src
  const imageSeo = articlePicture ? siteUrl+articlePicture.images.fallback.src : siteUrl+ogImage

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: imageSeo,
    url: `${siteUrl}${pathname}`,
    language: siteLanguage
  }

  //DataStructured - Logo
  let schemaLogo = null
  schemaLogo = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://hellohealth.com",
    "logo": siteUrl+defaultLogo.childImageSharp.gatsbyImageData.images.fallback.src
  }

  //DataStructured - Video Intro HH US
  let schemaVideoIntro = null
  if (datastructured === 'intro') {
    schemaVideoIntro = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hello Health® platorm Video - Technology Made for Independent Medical Practices",
      "description": "The Hello Health® EHR and Practice Management System fits the needs of many different practices and specialties. Get in touch with us to see if the Hello Health products are right for your practice!",
      "thumbnailUrl": siteUrl+defaultThumbnailIntro.childImageSharp.gatsbyImageData.images.fallback.src,
      "uploadDate": "2021-02-09",
      "contentUrl": "https://hellohealth.com/",
      "embedUrl": "https://www.youtube.com/embed/oLNcAprNoYc",
      "publisher" : {
        "@type" : "Organization",
        "name" : "Hello Health Inc.",
        "logo": {
          "@type": "ImageObject",
          "url":siteUrl+defaultLogo.childImageSharp.gatsbyImageData.images.fallback.src
        }
      },
    }
  }

  //DataStructured - Video Demo HH US
  let schemaVideoDemo = null
  if (datastructured === 'demo') {
    schemaVideoDemo = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "The Hello Health® EHR and Practice Management System Demo Video",
      "description": "Hello Health® Platform help practices do more with their workflow and be more efficient. Want to see Hello Health in action? Click on the video and watch a 5-minute.",
      "thumbnailUrl": siteUrl+defaultThumbnailDemo.childImageSharp.gatsbyImageData.images.fallback.src,
      "uploadDate": "2018-08-13",
      "contentUrl": "https://hellohealth.com/demo-5minute/",
      "embedUrl": "https://www.youtube.com/embed/XAmQEA8i4tI",
      "publisher" : {
        "@type" : "Organization",
        "name" : "Hello Health Inc.",
        "logo": {
          "@type": "ImageObject",
          "url":siteUrl+defaultLogo.childImageSharp.gatsbyImageData.images.fallback.src
        }
      },
    }
  }

  //DataStructured - Schema FAQ
  let schemaFAQ = null
  var mainEntityFaq = []
  if (datastructuredfaq === 'faq-hellohealth') {
    dataFAQ.nodes.map(item => {
      return mainEntityFaq.push({
        "@type": "Question",
        "name": item.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": item.acfPostFaq.response
        }
      })
    })
  }else if (datastructuredfaq === 'faq-telehealth') {
    dataFAQtelehealth.nodes.map(item => {
      return mainEntityFaq.push({
        "@type": "Question",
        "name": item.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": item.acfPostFaqTelehealth.responseTelehealth
        }
      })
    })
  }

  schemaFAQ = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": mainEntityFaq
  }

  //DataStructured - Schema Article Blog and News
  let schemaArticle = null
  if (datastructured === 'article') {

    var arrdataArticle = dataArticle.nodes.filter(function(element){
      return element.databaseId === articleId
    })

    arrdataArticle.map(itemArticle => {
      let arrTag = []
      let stringTag = null
      itemArticle.tags.nodes.map(tagname => {
        return arrTag.push(tagname.name)
      })
      stringTag = arrTag.join()

      var typeArticle = (itemArticle.categories.nodes[0].slug === "blog") ? "BlogPosting":"NewsArticle"

      schemaArticle = {
        "@context" : "https://schema.org",
        "@type" : typeArticle,
        "name" : itemArticle.title,
        "author" : {
          "@type" : "Person",
          "name" :  itemArticle.author.node.name
        },
        "keywords": stringTag,
        "datePublished" :  itemArticle.date,
        "image" : siteUrl+articlePicture.images.fallback.src,
        "url" : siteUrl+"/"+itemArticle.categories.nodes[0].slug+"/"+itemArticle.slug+"/",
        "headline" : itemArticle.title,
        "publisher" : {
          "@type" : "Organization",
          "name" : "Hello Health Inc.",
          "logo": {
            "@type": "ImageObject",
            "url":siteUrl+defaultLogo.childImageSharp.gatsbyImageData.images.fallback.src
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://hellohealth.com/"+itemArticle.categories.nodes[0].slug
        },
        "dateModified": itemArticle.modified
      }
      return schemaArticle
    })
  }

  return (
    <Helmet title={seo.title} >
      <html lang={siteLanguage} class={classHtml}/>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {datastructured === 'article' ? (<meta property="og:type" content="article" />) : (<meta property="og:type" content="website"></meta>)}
      <meta property="og:url" content={seo.url}/>
      <meta property="og:title" content={seo.title}/>
      <meta property="og:description" content={seo.description}/>
      <meta property="og:image" content={seo.image}/>
      <meta property="og:locale" content={siteLanguage} />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta property="twitter:domain" content="hellohealth.com" />
      <meta property="twitter:url" content={seo.url}/>
      <meta name="twitter:title" content={seo.title}/>
      <meta name="twitter:description" content={seo.description}/>
      <meta name="twitter:image" content={seo.image} />

      <script type="application/ld+json">{JSON.stringify(schemaLogo)}</script>
      {datastructured === 'intro' && <script type="application/ld+json">{JSON.stringify(schemaVideoIntro)}</script>}
      {datastructured === 'demo' && <script type="application/ld+json">{JSON.stringify(schemaVideoDemo)}</script>}
      {datastructured === 'faqs' && <script type="application/ld+json">{JSON.stringify(schemaFAQ)}</script>}
      {datastructured === 'article' && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}

      {slickslider === 'slick' &&  <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />}
      {slickslider === 'slick' && <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>}
      {classbody && <body className={classbody} />}

    </Helmet>
  )
}

/* Function propTypes declaration */
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  datastructured: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  datastructured: null
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        siteUrl
        siteLanguage
      }
    }
    defaultImage: file(relativePath: { eq: "og_hellohealth.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    defaultLogo: file(relativePath: { eq: "hellohealth-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    defaultThumbnailDemo: file(relativePath: { eq: "background/hero_demo.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    defaultThumbnailIntro: file(relativePath: { eq: "background/hero_about.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    dataFAQ: allWpFaq (sort: {fields: id}) {
      nodes {
        acfPostFaq {
          response
        }
        title
      }
    }
    dataFAQtelehealth: allWpFaqtelehealth (sort: {fields: id}) {
      nodes {
        acfPostFaqTelehealth {
          responseTelehealth
        }
        title
      }
    }
    dataArticle: allWpPost(sort: {fields: [date], order:DESC}) {
      nodes {
        databaseId
        title
        slug
        date
        modified
        author {
          node {
            name
          }
        }
        categories {
          nodes {
            slug
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`

/* Export function */
export default SEO
