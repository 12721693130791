/**
 * Class element
 * Header desktop component
 * Display the header
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import Ctabutton from "../components/ctabutton"
import Logo from "../components/logo"
import Navmaindesktop from "../components/navmaindesktop"
import "../styles/components/header.scss"

/* Declaration class */
class Headerfixeddesktop extends Component {

  constructor() {
    super()
    this.state = {
      stickyNav: false
    }
  }

  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    var mainheader = [].slice.call((document.getElementsByClassName("main-header")))
    var topsection = [].slice.call((document.getElementsByClassName("top-section")))
    var wrapmegamenudesktop = [].slice.call((document.getElementsByClassName("wrap-mega-submenu-desktop")))

    if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      this.setState({
        stickyNav: true
      })
      mainheader[0].classList.add("sticky")
      topsection[0].classList.add("sticky-nav")
      wrapmegamenudesktop[0].classList.add("sticky")
    }else{
      this.setState({
        stickyNav: false
      })
      mainheader[0].classList.remove("sticky")
      topsection[0].classList.remove("sticky-nav")
      wrapmegamenudesktop[0].classList.remove("sticky")
    }
  }

  render () {
    const classSticky = (this.state.stickyNav) ? "sticky":""

    return (
      <div className={`fixed-header desktop ${classSticky}`}>
        <div className="wrap-header">
          <div className="main-logo">
            <Link to="/"><Logo type="main" /></Link>
          </div>
          <Navmaindesktop key="desktop-menu" className="desktop-menu" />
          <Ctabutton idCta="ctaGetstarted-desktop" classCta="cta-getstarted" colorStyle="blue-light-format" ctaLabel="Get Started" ctaLink="/get-started" />
        </div>
      </div>
    )
  }
}

/* Export class */
export default Headerfixeddesktop
