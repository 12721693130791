/**
 * Const with param element
 * Layout component
 * Display the main layout
 */
/* Import section */
import PropTypes from "prop-types"
import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/components/layout.scss"

/* Declaration function */
const Layout = ({ children }) => {

  return (
    <>
      <Header />

      <main>{children}</main>

      <Footer />
    </>
  )
}

/* Function propTypes declaration */
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

/* Export function */
export default Layout
