/**
 * Class element
 * Header tablet component
 * Display the header
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { BsList } from "react-icons/bs"
import Ctabutton from "../components/ctabutton"
import Logo from "../components/logo"
import "../styles/components/header.scss"

/* Declaration class */
class Headerfixedtablet extends Component {

  constructor() {
    super()
    this.state = {
      width: 0,
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  manageBurger(boolStateBurger) {
    var navcontainer = [].slice.call((document.getElementsByClassName("nav-container-tablet")))
    var headermegamenutablet = [].slice.call((document.getElementsByClassName("header-mega-menu-tablet")))
    var wrapmegamenutablet = [].slice.call((document.getElementsByClassName("wrap-mega-menu-tablet")))
    var megamenuitem = [].slice.call((document.getElementsByClassName("mega-menu-item-tablet")))
    var menuitem = [].slice.call((document.getElementsByClassName("menu-item-tablet")))
    var svgicon = [].slice.call((document.getElementsByClassName("svg-icon")))
    var submenu = [].slice.call((document.getElementsByClassName("submenu-tablet")))

    if(boolStateBurger === true){
      document.body.style.overflow = 'hidden'
      headermegamenutablet[0].classList.add("show")
      wrapmegamenutablet[0].classList.add("show")
    }else{
      document.body.style.overflow = 'auto'
      navcontainer[0].classList.remove("dropped")
      headermegamenutablet[0].classList.remove("show")
      wrapmegamenutablet[0].classList.remove("show")
      megamenuitem.forEach((item) => {
        item.classList.remove("current")
        item.classList.add("inactive")
      })
      menuitem.forEach((item) => {
        item.classList.remove("current")
      })
      svgicon.forEach((item) => {
        item.classList.remove("current")
      })
      submenu.forEach((item) => {
        item.classList.remove("current")
      })
    }

    this.setState({
      width: window.innerWidth,
    })
  }

  render () {
    const isMobile = this.state.width <= 767

    return (
      <div className="fixed-header tablet">
        <div className="wrap-header">
          <div className="main-logo">
            <Link to="/">{!isMobile ?  <Logo type="main" /> : <Logo type="icon" /> }</Link>
          </div>
          <Ctabutton idCta="ctaGetstarted-tablet" classCta="cta-getstarted" colorStyle="blue-light-format" ctaLabel="Get Started" ctaLink="/get-started" />
          <div className="open-burger">
            <button className="button-burger" onClick={() => this.manageBurger(true)} aria-label="Open the menu" >
              <span>
                <IconContext.Provider value={{ className: "svg-burger" }}>
                <i aria-hidden="true"><BsList /></i>
                </IconContext.Provider>
              </span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

/* Export class */
export default Headerfixedtablet
