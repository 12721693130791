/**
 * Const with StaticQuery element
 * Footer component
 * Display a footer
 */
/* Import section */
import { Link } from "gatsby"
import React from "react"
import Logo from "../components/logo"
import Mediasocial from "../components/mediasocial"
import MenuNavJSon from "../../content/menu-nav.json"
import "../styles/components/footer.scss"

/* Declaration function with StaticQuery */
const Footer = () => {
  return (
    <footer className="footer">
      <section className="logo-footer">
        <div className="box-logo-footer">
          <Logo type="white" />
        </div>
      </section>
      <section className="nav-footer">
        <div className="box-nav-footer">
          <ul>
            <li className="submenu-category"><span>{MenuNavJSon.megaMenuSolutions[0].itemCat}</span></li>
            {MenuNavJSon.subListProducts.map( (listProducts, index) =>
              <li key={ `product-${index}` }><Link to={listProducts.to}>{listProducts.cta}</Link></li>
            )}
          </ul>
        </div>
        <div className="box-nav-footer">
          <ul>
            <li className="submenu-category"><span>{MenuNavJSon.megaMenuSolutions[1].itemCat}</span></li>
            {MenuNavJSon.subListSpecialties.map( (listSpecialties, index) =>
              <li key={ `specialty-${index}` }><Link to={listSpecialties.to}>{listSpecialties.cta}</Link></li>
            )}
          </ul>
        </div>
        <div className="box-nav-footer">
          <ul>
            <li className="submenu-category"><span>{MenuNavJSon.megaMenuSolutions[2].itemCat}</span></li>
            {MenuNavJSon.subListBenefits.map( (listBenefits, index) =>
              <li key={ `benefit-${index}` }><Link to={listBenefits.to}>{listBenefits.cta}</Link></li>
            )}
          </ul>
        </div>
        <div className="box-nav-footer">
          <ul>
            <li className="submenu-category"><span>{MenuNavJSon.megaMenuCompany[0].itemCat}</span></li>
            {MenuNavJSon.subListCompany.map( (listCompany, index) =>
              <li key={ `company-${index}` }><Link to={listCompany.to}>{listCompany.cta}</Link></li>
            )}
          </ul>
        </div>
      </section>
      <section className="bottom-footer">
        <div className="box-copyright box-copyright-desktop">
          <p>Hello Health Inc. ©{new Date().getFullYear()}</p>
          <p><Link to={MenuNavJSon.simpleMenuItem[4].to}>{MenuNavJSon.simpleMenuItem[4].cta}</Link></p>
          <p><Link to={MenuNavJSon.simpleMenuItem[5].to}>{MenuNavJSon.simpleMenuItem[5].cta}</Link></p>
        </div>
        <div className="box-copyright box-copyright-tablet">
        <p><Link to={MenuNavJSon.simpleMenuItem[4].to}>{MenuNavJSon.simpleMenuItem[4].cta}</Link></p>
          <p>Hello Health Inc. ©{new Date().getFullYear()}</p>
          <p><Link to={MenuNavJSon.simpleMenuItem[5].to}>{MenuNavJSon.simpleMenuItem[5].cta}</Link></p>
        </div>
        <div className="box-media-social">
          <Mediasocial />
        </div>
      </section>
    </footer>
  )
}
/* Export function */
export default Footer
