/**
 * Class element
 * Main Nav component
 * Display the main nav in header
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import Megamenudesktop from "../components/megamenudesktop"
import MenuNavJSon from "../../content/menu-nav.json"
import "../styles/components/navmaindesktop.scss"

/* Declaration class */
class Navmaindesktop extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeLink: 0,
    }
    this.clickOutsideMenuNavDesktop = this.clickOutsideMenuNavDesktop.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.clickOutsideMenuNavDesktop)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutsideMenuNavDesktop)
  }

  clickOutsideMenuNavDesktop = (e) => {
    var wrapmegamenudesktop = [].slice.call((document.getElementsByClassName("wrap-mega-submenu-desktop")))
    if(e.target.className !== "span-item" && e.target.className.animVal !== "icon-arrow" && e.target.className !== "menu-item" && e.target.className !== "menu-item current") {
      this.setState({
        activeLink: 0
      })
      wrapmegamenudesktop[0].classList.remove("active")
    }
  }

  handleClick = (e, tabId) => {
    var wrapmegamenudesktop = [].slice.call((document.getElementsByClassName("wrap-mega-submenu-desktop")))
    if(e.currentTarget.className === "mega-menu-item current") {
      this.setState({
        activeLink: 0
      })
      wrapmegamenudesktop[0].classList.remove("active")
    }else{
      this.setState({
        activeLink: tabId
      })
      wrapmegamenudesktop[0].classList.add("active")
    }
  }

  render () {
    //Elements of the Mega Menu - Solutions
    let arrSubListProducts = MenuNavJSon.subListProducts
    let arrSubListSpecialties= MenuNavJSon.subListSpecialties
    let arrSubListBenefits = MenuNavJSon.subListBenefits
    let arrSubListServices = MenuNavJSon.subListServices
    let arrMegaMenuSolutions = [
      {itemColor:  MenuNavJSon.megaMenuSolutions[0].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[0].itemCat, arrItemCat: arrSubListProducts},
      {itemColor:  MenuNavJSon.megaMenuSolutions[1].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[1].itemCat, arrItemCat: arrSubListSpecialties},
      {itemColor:  MenuNavJSon.megaMenuSolutions[2].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[2].itemCat, arrItemCat: arrSubListBenefits},
      {itemColor:  MenuNavJSon.megaMenuSolutions[3].itemColor, itemCat: MenuNavJSon.megaMenuSolutions[3].itemCat, arrItemCat: arrSubListServices},
    ]

    //Elements of the Mega Menu - Learn
    let arrSubListFaqs = MenuNavJSon.subListFaqs
    let arrSubListTrainingCenter= MenuNavJSon.subListTrainingCenter
    let arrSubListReleaseCenter= MenuNavJSon.subListReleaseCenter
    let arrMegaMenuLearn = [
      {itemColor:  MenuNavJSon.megaMenuLearn[0].itemColor, itemCat: MenuNavJSon.megaMenuLearn[0].itemCat, arrItemCat: arrSubListFaqs},
      {itemColor:  MenuNavJSon.megaMenuLearn[1].itemColor, itemCat: MenuNavJSon.megaMenuLearn[1].itemCat, arrItemCat: arrSubListTrainingCenter},
      {itemColor:  MenuNavJSon.megaMenuLearn[2].itemColor, itemCat: MenuNavJSon.megaMenuLearn[2].itemCat, arrItemCat: arrSubListReleaseCenter},
    ]

    //Elements of the Mega Menu - Resources
    let arrSubListBlogNews = MenuNavJSon.subListBlogNews
    let arrSubListResource1= MenuNavJSon.subListResource1
    let arrSubListResource2= MenuNavJSon.subListResource2
    let arrMegaMenuResources = [
      {itemColor:  MenuNavJSon.megaMenuResources[0].itemColor, itemCat: MenuNavJSon.megaMenuResources[0].itemCat, arrItemCat: arrSubListBlogNews},
      {itemColor:  MenuNavJSon.megaMenuResources[1].itemColor, itemCat: MenuNavJSon.megaMenuResources[1].itemCat, arrItemCat: arrSubListResource1},
      {itemColor:  MenuNavJSon.megaMenuResources[2].itemColor, itemCat: MenuNavJSon.megaMenuResources[2].itemCat, arrItemCat: arrSubListResource2},
    ]

    return (
      <nav key="mainnav" className={ `main-nav ${ this.props.className }` } onClick={this.props.onClick} onKeyDown={this.props.onKeyDown} role="presentation">
        <ul key="list-mainnav" className="primary-menu" role="group">
          <Megamenudesktop
            key="solutions"
            tabList="solutions"
            nameList="Solutions"
            arrList={arrMegaMenuSolutions}
            current={this.state.activeLink === 1 && true}
            onClick={(e) => this.handleClick(e, 1)}
            onKeyDown={(e) => this.handleClick(e, 1)}/>
          <li className="simple-menu-item"><span className="menu-item no-dropdown"><Link to={MenuNavJSon.simpleMenuItem[0].to}>{MenuNavJSon.simpleMenuItem[0].cta}</Link></span></li>
          <li className="simple-menu-item"><span className="menu-item no-dropdown"><Link to={MenuNavJSon.simpleMenuItem[1].to}>{MenuNavJSon.simpleMenuItem[1].cta}</Link></span></li>
          <Megamenudesktop
            key="learn"
            tabList="learn"
            nameList="Learn"
            arrList={arrMegaMenuLearn}
            current={this.state.activeLink === 2 && true}
            onClick={(e) => this.handleClick(e, 2)}
            onKeyDown={(e) => this.handleClick(e, 2)}/>
          <Megamenudesktop
            key="resources"
            tabList="resources"
            nameList="Resources"
            arrList={arrMegaMenuResources}
            current={this.state.activeLink === 3 && true}
            onClick={(e) => this.handleClick(e, 3)}
          onKeyDown={(e) => this.handleClick(e, 3)}/>
        </ul>
      </nav>
    )
  }
}

/* Export function */
export default Navmaindesktop
