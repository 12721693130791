/**
 * Const with a param
 * Logo component
 * Display a logo
 */
/* Import section */
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

/* Declaration function */
const Logo = ({ type }) => {
  if(type === "main"){
    return <StaticImage src="../images/hellohealth-logo.png" alt="Logo Hello Health" layout="fixed" width={200} />
  }else if(type === "white"){
    return <StaticImage src="../images/hellohealth-logo-white.png" alt="Logo Hello Health white" layout="fixed" width={230} />
  }else if(type === "icon"){
    return <StaticImage src="../images/hellohealth-icon.png" alt="icon logo Hello Health" layout="fixed" width={16} height={30}  />
  }else {
    return <StaticImage src="../images/hellohealth-logo-white.png" alt="Logo Hello Health white" layout="fixed" width={180} />
  }
}

/* Export function */
export default Logo
